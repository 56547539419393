"use strict";

// import {findAncestorByClass, addClass, removeClass, hasClass, wrapElement} from './helpers.js';

const defaults = {
    selectors: {
        image: 'img',
    },
};

export default function () {
    let allImages = document.querySelectorAll(defaults.selectors.image);

    if (allImages.length > 0) {

        for (let i = 0; i < allImages.length; ++i) {
            bindImageCheck(allImages[i]);
        }
    }
}

function bindImageCheck(image) {
    image.onerror = function () {
        console.error("image load failed on: " + image.src);
        //do something else...
        // e.g. send to some logging service
    }
}
