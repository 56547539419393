<template>
    <!--<div class=" search-result">-->
        <a class="search-result-item headcol-item headcol-link" :href="suggestion.link"
           :data-suggestion-id="suggestion.id"
           :data-type="suggestion.type_id"
           :data-package="suggestion.package_id"
        @click="handleClick">

              <span class="search-result-image" v-if="suggestion.imagesrc">
                  <span class="item__image-wrapper">
                      <img :src="suggestion.imagesrc" width="102" height="102" alt="">
                  </span>
              </span>

              <span class="search-result-description">
                  <span class="search-result-name delta">{{suggestion.name}}</span>
                  <span class="search-result-headline text">{{suggestion.headline}}</span>
              </span>
        </a>
    <!--</div>-->

</template>

<script>

    import {sendEvent} from "../common/analytics";

    export default {
        name: 'search-suggestion',
        data () {
            return {

            }
        },
        props: ['suggestion'],
        methods: {
            handleClick() {
                sendEvent('search-suggestion', 'click', this.suggestion.name + ' // ' + this.suggestion.link);
            }
        }
    }

</script>
