"use strict";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        scrollLink: '.js-scroll-link',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindScrollLinks() {
    let allScrollLinks = document.querySelectorAll(defaults.selectors.scrollLink);

    console.log(allScrollLinks);
    
    if (!allScrollLinks) return false;

    Array.from(allScrollLinks).forEach(scrollLink => {

        scrollLink.addEventListener('click', function (event) {
            // let target = event.target.hash;
            // console.log(event.target.hash);

          let targetHash = event.currentTarget.hash;

          if (!targetHash) {
            return false;
          }

          let target = document.querySelector(targetHash);
          // console.log(target);

          if (!target) {
            return false;
          }

            event.preventDefault();

            target.scrollIntoView({ behavior: 'smooth' });
        })
    });
}
