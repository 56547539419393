var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-results-container" },
    [
      _vm.hasSuggestions
        ? [
            _c(
              "div",
              { staticClass: "search-results headcol-list" },
              [
                _vm._l(_vm.suggestions, function (suggestion, index) {
                  return index + 1 < _vm.entriesToShow
                    ? _c("search-suggestion", {
                        key: suggestion.id,
                        attrs: { suggestion: suggestion },
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.entriesToShow < _vm.suggestions.length
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "search-result-item headcol-item headcol-link u-align-center",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showMoreEntries.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._m(0)]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : [
            _c("div", { staticClass: "search-no-results headcol-title" }, [
              _vm._v("\n            Leider keine Ergebnisse\n        "),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "search-result-description" }, [
      _c("span", { staticClass: "search-result-name delta" }, [
        _vm._v("MEHR ANZEIGEN"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }