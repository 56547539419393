"use strict";

import { lang } from '../helpers'
import sortByPackage from './../common/sort-by-package';
import {sendEvent} from "../common/analytics";

// import Modernizr from 'modernizr';

import axios from 'axios';
// import VueAxios from 'vue-axios';
import Vue from 'vue';
import SearchList from './SearchList.vue';

import _debounce from 'lodash/debounce';

// Vue.use(VueAxios, axios);

Vue.filter('uppercase', function (value) {
    return value.toUpperCase();
});

Vue.filter('nl2br', function (text) {
    return text.replace(/\n/g, '<br>');
});

// Vue.filter('decode_utf8', function (string) {
//     return string;
//     // return decodeURIComponent(escape(string));
// });


const defaults = {
    selectors: {
        searchContainer: '.js-search-container',
        searchForm: '.js-search-form',
        searchInput: '.js-search-input',
    }
};

export function initSearchForm () {

    if (!document.querySelector(defaults.selectors.searchContainer)) {
        return false;
    }

    // const searchWordFromEl = document.querySelector(defaults.selectors.searchForm).dataset.searchWord;

    // bindAnalyticsLinksInContainer(defaults.selectors.searchContainer);

    let searchForm = new Vue({
        el: '.js-search-container',
        name: 'searchForm',
        data() {
            return {
                // searchWordFromEl: searchWordFromEl,
                lang: lang,
                loading: true,
                searchWord: "",
                previousSearchWord: "",
                // currentSearch: '',
                // results: [],
                searchSuggestions: [],
                // suggestURL: "suggest.php",
                // searchURL: "search.php",
                queryUrl: '/api/listings/search',
                listingsBaseUrl: '/listings/',
                queryMinLength: 2,

                // queryParamName: 'query',
                // channelParamName: 'channel',
                // channel: 'sport-bittl_de',
                // channelBase: 'sport-bittl_',
            };
        },
        // template: '<Search/>',
        components: { SearchList },
        methods: {
            submitSearchForm () {

                if (this.searchWord.length <= this.queryMinLength) {
                    console.log('not long enough');
                    return false;
                }

                let url = window.location.protocol + '//' + window.location.host;


                url = url + '/' + this.searchURL + '?' + this.queryParamName +'='+ encodeURIComponent(this.searchWord);
                url = url.replace(/\+/g, '%20');

                sendEvent('searchfield', 'submit', this.searchWord);

                console.log(url);
                // window.location.href = url;
            },
            throttledGetSuggestions: _debounce(function (event) {
                this.getSuggestions(event)
            }, 1000),

            getSuggestions () {

                if (this.searchWord.length <= 0) {
                    console.log('housecleaning');
                    this.clearSearchResults();
                    return false;
                }

                if (this.searchWord.length <= this.queryMinLength) {
                    console.log('not long enough');
                    return false;
                }


                if (this.searchWord === this.previousSearchWord) {
                    console.log('same search term');
                    return false;
                }


                sendEvent('searchfield', 'type', this.searchWord);

                let query = this.queryUrl;

                axios.get(query, {
                    params: {
                        search: this.searchWord,
                        searchFields: [
                            'name',
                            'display_name'
                        ],
                        // searchFields: "name",
                        // with: 'places;tags;images',
                        status: 'published',

                    }
                }).then(response => {
                    this.searchSuggestions = this.cleanupData(response.data.data);
                    this.previousSearchWord = this.searchWord;
                    // this.sortSuggestions(response.data.suggestions)
                    console.log(response);

                    // let dataSet = {};
                    // dataSet[dateSlug] = response.data.data;
                    // console.log(dataSet);
                    // resolve(dataSet);

                    // resolve(response.data.data);
                })
                    .catch(function (error) {
                        console.log(error);
                    });

                // let requestURL = this.suggestURL +
                //     '?' + this.queryParamName +'='+ encodeURIComponent(this.searchWord) +
                //     '&'+ this.channelParamName +'='+ this.channelBase + this.lang +
                //     '&format=json&omitContextName=true';
                //
                // axios.get(requestURL)
                //     .then(response => this.sortSuggestions(response.data.suggestions))
                //     .then(this.previousSearchWord = this.searchWord)
                //     // .then(this.toggleLoading())
                //     //        .then(response => )
                //     .catch(function (error) {
                //         console.log(error);
                //     });
            },

            cleanupData(suggestions) {
                let newSuggestions =  suggestions.map(suggestion => {
                    suggestion.link = this.listingsBaseUrl + suggestion.slug;

                    suggestion.name = suggestion.display_name ? suggestion.display_name : suggestion.name;

                    return suggestion;
                });

                newSuggestions = sortByPackage(newSuggestions);
                // newSuggestions.sort((a, b) => {
                //
                //     // Sorting is inverse because higher package_id means better package means display first
                //     if (a.package_id > b.package_id) {
                //         return -1;
                //     }
                //     if (a.package_id < b.package_id) {
                //         return 1;
                //     }
                //     // a must be equal to b
                //     return 0;
                // });

                return newSuggestions;
            },

            // sortSuggestions(suggestions) {
            //     let tempSuggestions = {
            //         products: [],
            //         brands: [],
            //         pages: [],
            //         categories: [],
            //     };
            //
            //     suggestions.forEach(function(el, i) {
            //         switch (el.type) {
            //             case 'productName' :  //
            //                 tempSuggestions.products.push({
            //                     link: el.attributes.deeplink,
            //                     sugId: i,
            //                     id: el.attributes.id,
            //                     type: 'product',
            //                     brand: el.attributes.Marke,
            //                     title: el.name,
            //                     imagesrc: el.image,
            //                     searchparams: el.searchParams,
            //                 });
            //                 break;
            //             case 'brand': //
            //                 tempSuggestions.brands.push({
            //                     link: el.attributes.Markenlink,
            //                     sugId: i,
            //                     type: 'brand',
            //                     title: el.name,
            //                     imagesrc: el.attributes.Markenlogo,
            //                     searchparams: el.searchParams,
            //                 });
            //                 break;
            //             case 'category': //
            //                 tempSuggestions.categories.push({
            //                     link: el.attributes.deeplink,
            //                     sugId: i,
            //                     type: 'category',
            //                     title: el.name,
            //                     searchparams: el.searchParams,
            //                 });
            //                 break;
            //             case 'content': //
            //                 tempSuggestions.pages.push({
            //                     link: el.attributes.deeplink,
            //                     sugId: i,
            //                     type: 'page',
            //                     title: el.name,
            //                     searchparams: el.searchParams,
            //                 });
            //                 break;
            //             default :
            //                 break;
            //         }
            //     });
            //     this.searchSuggestions = tempSuggestions;
            // },
            clearSearchResults() {
                sendEvent('searchfield', 'clear', 'clearsearch');

                this.searchSuggestions = [];
                this.searchWord = "";
                this.previousSearchWord = "";
                // this.suggestions = [];
                // this.results = [];
            }
        },
        computed: {
            hasSuggestions: function () {
                return this.searchSuggestions.length > 0;
                // return this.searchSuggestions.products.length > 0 ||
                //     this.searchSuggestions.brands.length > 0 ||
                //     this.searchSuggestions.pages.length > 0 ||
                //     this.searchSuggestions.categories.length > 0
            },
            // hasProductSuggestions: function () {
            //     return this.searchSuggestions.products.length > 0;
            // },
        }
    });
}
