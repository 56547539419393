"use strict";


import {nodeListToArray, addClass, removeClass, isMobile, hasClass, findAncestorByClass} from "./helpers";
import _merge from 'lodash/merge';
import stickybits from 'stickybits'
// import forEach from 'lodash/forEach';

import {am_autocomplete, am_autocomplete_state} from "./search/site-search";

const defaults = {
    selectors: {
        navElement: '.js-site-nav',
        searchElement: '.js-site-search',
        searchInput: '#autocomplete .aa-Input',
        navToggle: '.js-site-nav-toggle',
        searchToggle: '.js-toggle-site-search',
        navContainer: '.js-site-nav-container',
    },
    classes: {
        toggleActiveClass: 'is-opened',
        navActiveClass: 'is-visible',
        initClass: 'is-nav-initialized'
    },
};

const state = {
    navOpen: false,
    searchOpen: false,
};

class SiteNav {
    constructor(navElement, options) {
        this.navElement = navElement;
        this.options = _merge(defaults, options);

        this.navContainer = findAncestorByClass(this.navElement, this.options.selectors.navContainer)
        this.navToggle = this.navContainer.querySelector(this.options.selectors.navToggle)
        this.searchElement = this.navContainer.querySelector(this.options.selectors.searchElement);
        this.searchToggle = this.navContainer.querySelector(this.options.selectors.searchToggle);

        window.am_autocomplete  = am_autocomplete;
        window.am_autocomplete_state  = am_autocomplete_state;
        console.log(am_autocomplete);
        console.log(am_autocomplete_state);

        this.state = this.setInitialState();

        this.bindToggles();

        console.log(this);

        addClass(this.navContainer, this.options.classes.initClass);
    }

    setInitialState() {
        return state;
    }


    bindToggles() {
        // let self = this;
        this.navToggle.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleNav()
        })

        if (this.searchToggle) {
            this.searchToggle.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleSearch()
            })
        }
    }

    toggleNav() {
        if(this.state.navOpen) {
            this.closeNav()
        } else {
            this.openNav()
        }
    }

    openNav() {
        addClass(this.navToggle, this.options.classes.toggleActiveClass);
        addClass(this.navElement, this.options.classes.navActiveClass);
        this.state.navOpen = true;
    }

    closeNav() {
        removeClass(this.navToggle, this.options.classes.toggleActiveClass);
        removeClass(this.navElement, this.options.classes.navActiveClass);
        this.state.navOpen = false;
    }

    toggleSearch() {
        if(this.state.searchOpen) {
            this.closeSearch()
        } else {
            this.openSearch()
        }
    }

    openSearch() {
        addClass(this.searchToggle, this.options.classes.toggleActiveClass);
        addClass(this.searchElement, this.options.classes.navActiveClass);

        this.searchInput = this.navContainer.querySelector(this.options.selectors.searchInput);

        this.searchInput.focus();
        this.state.searchOpen = true;
    }

    closeSearch() {
        removeClass(this.searchToggle, this.options.classes.toggleActiveClass);
        removeClass(this.searchElement, this.options.classes.navActiveClass);
        this.state.searchOpen = false;
    }
}


export function initSiteNavigation () {

    let navElements = document.querySelectorAll(defaults.selectors.navElement);

    console.log(navElements);

    if (navElements) {
        // make it stick
        // stickybits(defaults.selectors.navContainer, {useStickyClasses: true, customStickyChangeNumber: 200, useGetBoundingClientRect: true, stickyBitStickyOffset: 0});
        setTimeout(function () {
            const stickybit = stickybits(defaults.selectors.navContainer, {useStickyClasses: true, useFixed: true, customStickyChangeNumber: 300});
            console.log(stickybit);
            console.log("make it stick");

        }, 1000);

        Array.from(navElements).forEach(navElement => {
            new SiteNav(navElement);
        });
    }

}


