import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import {sendEvent} from "../common/analytics";
import {addClass, removeClass} from "../helpers";
// import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';


const settings = {
    queryParamName: 'query'
};

const searchClient = algoliasearch(
    'Z13LSKWEKB',
    'd36bbbfb00d364f28eb9ec7435857ffc'
);

// const querySuggestionsPluginContent = createQuerySuggestionsPlugin({
//     searchClient,
//     indexName: 'ac_content_query_suggestions',
// });
//
// const querySuggestionsPluginPlaces = createQuerySuggestionsPlugin({
//     searchClient,
//     indexName: 'am_places_listings_query_suggestions',
// });
//
// const querySuggestionsPluginEvents = createQuerySuggestionsPlugin({
//     searchClient,
//     indexName: 'am_places_events_query_suggestions',
// });

export var am_autocomplete;
export var am_autocomplete_state = {};

export function initSiteSearch () {

    let submitButton = document.querySelector('.js-show-results-button');
    let submitButtonTextPlaceholder = submitButton.dataset.text;

    let resultsContainer = document.querySelector('.js-results-container');

    let searchPage = document.querySelector('#autocomplete').dataset.searchPage;
    let baseurl = window.location.protocol + '//' + window.location.host;

    function buildSearchPageQuery(state) {
        if(state.query.length <=0 ){
            return null;
        }
        // debugger;
        let url = baseurl + searchPage + '?' + settings.queryParamName +'='+ encodeURIComponent(state.query);
        url = url.replace(/\+/g, '%20');

        console.log(url);
        return url;
    }

    function updateSubmitButton (url, count) {

        submitButton.href = url;
        submitButton.innerText = submitButtonTextPlaceholder.replace('%count%', count)

        if(count > 0) {
            removeClass(submitButton, 'u-hide');
        } else {
            addClass(submitButton, 'u-hide');
        }
    }

    function updateResultsContainer(count) {
        if(count > 0) {
            removeClass(resultsContainer, 'u-hide');
        } else {
            addClass(resultsContainer, 'u-hide');
        }
    }

    function updateOnStateChange(state) {

        let count = 0;

        state.collections.forEach(collection => {
            count += collection.items.length
        })

        let url = buildSearchPageQuery(state);

        updateSubmitButton(url, count);
        updateResultsContainer(count)
    }

    let aaInput = undefined;

    am_autocomplete = autocomplete({
        container: '#autocomplete',
        panelContainer: '#autocomplete-results',
        panelPlacement: 'full-width',
        placeholder: 'Orte, Namen, Events, …',
        openOnFocus: true,
        insights: true,
        ignoreCompositionEvents: true,
        // autoFocus: true,
        debug: true,
        onStateChange({state}) {
            console.log(state);
            updateOnStateChange(state)
            am_autocomplete_state = state;
            // if (!aaInput && document.querySelector(".aa-Input")) {
            //     aaInput = document.querySelector(".aa-Input").outerHTML;
            //     aaInput.enterKeyHint = 'suchen';
            // }
        },
        onSubmit({state, event}) {

            let url = buildSearchPageQuery(state);

            sendEvent('searchfield', 'submit', state.query);

            window.location.href = url;
        },
        onReset({state}){
            console.log("reset");
            console.log(state);
        },

        // plugins: [algoliaInsightsPlugin, querySuggestionsPlugin],

        getSources({ query, state }) {
            if (!query) {
                return [];
            }

            return [
                {
                    sourceId: 'listings',
                    getItemUrl({ item }) {
                        return item.path;
                    },
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: 'am_places_listings',
                                    query,
                                    params: {
                                        clickAnalytics: true,
                                        // attributesToSnippet: ['name:10'],
                                        // snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },
                    templates: {
                        // header() {
                        //     return 'Orte';
                        // },
                        //
                        item({ item, createElement }) {
                            return createElement('a',
                                {
                                    class: 'site-search-result',
                                    href: item.path,
                                    dangerouslySetInnerHTML: {
                                        __html: `<span class="result-title">${item.title}</span><span class="result-category">in Orte</span>`,
                                    },
                                });
                        },


                        // item({ item }) {
                        //     console.log(item);
                        //     return `Result: ${item.title}`;
                        // },

                        noResults() {
                            return false;
                        },
                    },
                },

                {
                    sourceId: 'events',
                    getItemUrl({ item }) {
                        return item.path;
                    },
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: 'am_places_events',
                                    query,
                                    params: {
                                        clickAnalytics: true,
                                        // attributesToSnippet: ['name:10'],
                                        // snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },
                    templates: {
                        // header() {
                        //     return 'Veranstaltungen';
                        // },

                        item({ item, createElement }) {
                            return createElement('a',
                                {
                                    class: 'site-search-result',
                                    href: item.path,
                                    dangerouslySetInnerHTML: {
                                        __html: `<span class="result-title">${item.title}</span><span class="result-category">in Veranstaltungen</span>`,
                                    },
                                });
                        },


                        noResults() {
                            return false;
                        },
                    },
                },

                {
                    sourceId: 'pages',
                    getItemUrl({ item }) {
                        return item.path;
                    },
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: 'ac_content',
                                    query,
                                    params: {
                                        clickAnalytics: true,
                                        // attributesToSnippet: ['name:10'],
                                        // snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },
                    templates: {
                        // header() {
                        //     return 'Veranstaltungen';
                        // },

                        item({ item, createElement }) {
                            return createElement('a',
                                {
                                    class: 'site-search-result',
                                    href: item.path,
                                    dangerouslySetInnerHTML: {
                                        __html: `<span class="result-title">${item.title}</span><span class="result-category">in Seiten</span>`,
                                    },
                                });
                        },

                        noResults() {
                            return false;
                        },
                    },
                },


            ];
        },
    });


    // autocomplete({
    //     container: '#autocomplete',
    //     placeholder: 'Search for things',
    //     getSources({ query }) {
    //         return [
    //             {
    //                 sourceId: 'listings',
    //                 getItems() {
    //                     return getAlgoliaResults({
    //                         searchClient,
    //                         queries: [
    //                             {
    //                                 indexName: 'am_places_listings',
    //                                 query,
    //                                 params: {
    //                                     hitsPerPage: 5,
    //                                 },
    //                             },
    //                         ],
    //                     });
    //                 },
    //             },
    //         ];
    //     },
    // });

    // autocomplete({
    //     container: '#autocomplete',
    //     placeholder: 'Search for products',
    //     debug: true,
    //     // plugins: [querySuggestionsPluginContent, querySuggestionsPluginPlaces, querySuggestionsPluginEvents],
    //
    //     getSources({ query }) {
    //         return [
    //             // {
    //             //     sourceId: 'querySuggestions',
    //             //     getItems() {
    //             //         return getAlgoliaResults({
    //             //             searchClient,
    //             //             queries: [
    //             //                 {
    //             //                     indexName: 'ac_content',
    //             //                     query,
    //             //                 },
    //             //             ],
    //             //         });
    //             //     },
    //             //     // getItemInputValue() {
    //             //     //     return item.query;
    //             //     // },
    //             // },
    //             {
    //                 sourceId: 'listings',
    //                 getItems() {
    //                     return getAlgoliaResults({
    //                         searchClient,
    //                         queries: [
    //                             {
    //                                 indexName: 'am_places_listings',
    //                                 query,
    //                                 params: {
    //                                     hitsPerPage: 5,
    //                                 },
    //                             },
    //                         ],
    //                         templates: {
    //                             item({ item }) {
    //                                 return `Result: ${item.name}`;
    //                             },
    //                             noResults() {
    //                                 return 'No results.';
    //                             },
    //                         },
    //                     });
    //                 },
    //             },
    //         ];
    //     },
    // });

}
