"use strict";

export default function (listings) {
    listings.sort((a, b) => {

        // special case for stadtmarkt (id 5) and altstadt (id 6). Gets ranked like non-member
        if(a.package_id === 5 || a.package_id === 6) {
            if (1 > b.package_id) {
                return -1;
            }
            if (1 < b.package_id) {
                return 1;
            }
        }
        if(b.package_id === 5 || b.package_id === 6) {
            if (a.package_id > 1) {
                return -1;
            }
            if (a.package_id < 1) {
                return 1;
            }
        }

        // Sorting is inverse because higher package_id means better package means display first
        if (a.package_id > b.package_id) {
            return -1;
        }
        if (a.package_id < b.package_id) {
            return 1;
        }
        // a must be equal to b
        return 0;
    });

    return listings;
}
