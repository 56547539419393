import { lang, isMobile, getResponsiveTag } from './components/helpers.js'
import 'es6-promise/auto';
import checkImages from "./components/image-checker";
import bindScrollLinks from './components/scroll-links';
import {initAccordions} from "./components/accordions";
import {initSiteNavigation} from "./components/site-navigation";
import {initGallerySlideshows} from "./components/gallery-slideshow";
import {initTeaserSlideshows} from "./components/sliders";
import bindScrollTop from './components/arrow-to-top';
import {initSearchForm} from "./components/search/init-search";
import {initSiteSearch} from "./components/search/site-search";
import initVbcn from "./components/vbcn";
import vbcnOptions from "./config/consent-settings";
import initEmergencyModal from "./components/emergency-modal";
import {initVideos} from "./components/videos";

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

function initGlobal() {
    window.vbcn = initVbcn(vbcnOptions);

    console.log('Language from main: ' + lang);
    console.log('responsive tag: ' + getResponsiveTag());

    //GLOBAL
    initEmergencyModal();
    initSiteSearch();
    initSiteNavigation();
    // initSearchForm();
    bindScrollLinks();
    initAccordions();
    initTeaserSlideshows();
    initGallerySlideshows();
    bindScrollTop();
    initVideos();
}

export default function () {
    checkImages();
    initGlobal();
}
