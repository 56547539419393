<template>
    <div class="search-results-container">
        <!--<div class="search-results-header headcol-title" >-->
                <!--Ergebnisse-->
        <!--</div>-->
        <template v-if="hasSuggestions">

            <div class="search-results headcol-list">
                <search-suggestion v-for="(suggestion, index) in suggestions" :key="suggestion.id" :suggestion="suggestion"  v-if="index + 1 < entriesToShow"></search-suggestion>

                <!--<div class="discover-list-entry" v-if="entriesToShow < suggestions.length">-->
                    <!--<div class="card-story card-story-more ">-->
                        <!--<a href="#" class="btn-secondary" @click.prevent="showMoreEntries">MEHR ANZEIGEN</a>-->
                    <!--</div>-->
                <!--</div>-->

                <a href="#" class="search-result-item headcol-item headcol-link u-align-center" @click.prevent="showMoreEntries" v-if="entriesToShow < suggestions.length">
                    <span class="search-result-description">
                        <span class="search-result-name delta">MEHR ANZEIGEN</span>
                    </span>
                </a>


            </div>
        </template>
        <template v-else>
            <div class="search-no-results headcol-title">
                Leider keine Ergebnisse
            </div>
        </template>

        <!--<div class="wrap">-->
            <!--<div class="grid">-->

                <!--<template v-if="hasProductSuggestions">-->
                    <!--<div class="search__products grid__col grid__col&#45;&#45;2-of-4">-->

                        <!--<div class="grid search__section" :class="[activeSection === 'products' ? sectionActiveClass : '']" ref="products">-->
                            <!--<div class=" grid__col grid__col&#45;&#45;2-of-2">-->
                                <!--<div class="search__suggest-header" v-on:click="setActiveSection('products')">-->
                                    <!--{{langStrings.headings.search.products}}-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="search__section__content">-->
                                <!--<search-product v-for="product in suggestions.products" :key="product.id" :product="product"></search-product>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</template>-->

                <!--<template v-if="hasSuggestions">-->
                    <!--<div class="search__suggestions grid__col grid__col&#45;&#45;2-of-4">-->

                        <!--<div class="grid search__section">-->
                            <!--<div class=" grid__col grid__col&#45;&#45;2-of-2">-->
                                <!--<div class="search__suggest-header" >-->
                                    <!--Ergebnisse-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="search__section__content">-->
                                <!--<search-suggestion v-for="suggestion in suggestions" :key="suggestion.id" :suggestion="suggestion"></search-suggestion>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</template>-->

                <!--<template v-if="hasNonProductSuggestions">-->
                    <!--<div class="search__others grid__col grid__col&#45;&#45;2-of-4">-->

                        <!--<div class="grid search__section" v-if="suggestions.brands.length > 0" :class="[activeSection === 'brands' ? sectionActiveClass : '']" ref="brands">-->
                            <!--<div class=" grid__col grid__col&#45;&#45;2-of-2">-->
                                <!--<div class="search__suggest-header" v-on:click="setActiveSection('brands')">-->
                                    <!--{{langStrings.headings.search.brands}}-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="search__section__content">-->
                                <!--<search-brand v-for="brand in suggestions.brands" :key="brand.id" :brand="brand"></search-brand>-->
                            <!--</div>-->
                        <!--</div>-->

                        <!--<div class="grid search__section" v-if="suggestions.categories.length > 0" :class="[activeSection === 'categories' ? sectionActiveClass : '']" ref="categories">-->
                            <!--<div class="grid__col grid__col&#45;&#45;2-of-2">-->
                                <!--<div class="search__suggest-header" v-on:click="setActiveSection('categories')">-->
                                    <!--{{langStrings.headings.search.categories}}-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="search__section__content">-->
                                <!--<search-category v-for="category in suggestions.categories" :key="category.id" :category="category"></search-category>-->
                            <!--</div>-->
                        <!--</div>-->

                        <!--<div class="grid search__section" v-if="suggestions.pages.length > 0" :class="[activeSection === 'pages' ? sectionActiveClass : '']" ref="pages">-->
                            <!--<div class="grid__col grid__col&#45;&#45;2-of-2">-->
                                <!--<div class="search__suggest-header" v-on:click="setActiveSection('pages')">-->
                                    <!--{{langStrings.headings.search.pages}}-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="search__section__content">-->
                                <!--<search-page v-for="page in suggestions.pages" :key="page.id" :page="page"></search-page>-->
                            <!--</div>-->
                        <!--</div>-->

                    <!--</div>-->
                <!--</template>-->

            </div>
        <!--</div>-->
    <!--</div>-->
</template>

<script>
    import Vue from 'vue';

    // import SearchProduct from './product.vue';
    // import SearchBrand from './brand';
    // import SearchPage from './page';
    // import SearchCategory from './category';
    import SearchSuggestion from './Suggestion';
    import {sendEvent} from "../common/analytics";

    import {lang, isMobile} from "../helpers";
    // import getLangStrings from './../langstrings'


    export default {
        name: 'search-list',
        // template: '.js-search-form',
        components: {
            // SearchProduct, SearchBrand, SearchPage, SearchCategory
            SearchSuggestion
        },
        props: ['suggestions'],

        data() {
            return {
                lang: lang,
                entriesToShow: 5,
                // langStrings: getLangStrings(),
                // sectionActiveClass: 'is-open',
                // activeSection: '',
            };
        },

        methods: {
            showMoreEntries() {
                this.entriesToShow = this.suggestions.length;
                sendEvent('searchlist', 'click', 'show all');

            },
            // setActiveSection: function (section) {
            //     this.activeSection = section;
            //
            //     console.log(section);
            //     console.log(isMobile());
            //
            //     if (isMobile()) {
            //         // target.scrollIntoView({ behavior: 'smooth' });
            //         this.scrollToSection(section)
            //     }
            // },
            // scrollToSection: function(section) {
            //     let self = this;
            //     // console.log(section);
            //     // console.log(this.$refs[section]);
            //     setTimeout(function () {
            //         self.$refs[section].scrollIntoView({ behavior: 'smooth', block: 'start' });
            //     }, 20)
            // },
            // checkActiveSection: function () {
            //     if (this.suggestions[this.activeSection].length <= 0) {
            //         this.setActiveToFirstAvailableSection();
            //     }
            // },
            // setActiveToFirstAvailableSection: function () {
            //
            //     if (this.suggestions.products.length > 0) {
            //         this.setActiveSection('products');
            //     } else if (this.suggestions.brands.length > 0) {
            //         this.setActiveSection('brands');
            //     } else if (this.suggestions.categories.length > 0) {
            //         this.setActiveSection('categories');
            //     } else if (this.suggestions.pages.length > 0) {
            //         this.setActiveSection('pages');
            //     }
            // }
        },
        computed: {
            hasSuggestions: function () {
                return this.suggestions.length > 0;
                // return this.suggestions.products.length > 0 ||
                //     this.suggestions.brands.length > 0 ||
                //     this.suggestions.pages.length > 0 ||
                //     this.suggestions.categories.length > 0
            },
            // hasProductSuggestions: function () {
            //     return this.suggestions.products.length > 0;
            // },
            // hasNonProductSuggestions: function () {
            //     return this.suggestions.brands.length > 0 ||
            //         this.suggestions.pages.length > 0 ||
            //         this.suggestions.categories.length > 0
            // }
        },
        mounted: function () {

            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                // this.setActiveToFirstAvailableSection();
            })
        },
        updated: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been re-rendered
                // if (this.suggestions.products.length > 0) {
                //     this.setActiveSection('products');
                // } else if (this.suggestions.brands.length > 0) {
                //     this.setActiveSection('brands');
                // } else if (this.suggestions.categories.length > 0) {
                //     this.setActiveSection('categories');
                // } else if (this.suggestions.pages.length > 0) {
                //     this.setActiveSection('pages');
                // }
                // this.checkActiveSection();
            })
        }
    }
</script>
