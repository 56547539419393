var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "search-result-item headcol-item headcol-link",
      attrs: {
        href: _vm.suggestion.link,
        "data-suggestion-id": _vm.suggestion.id,
        "data-type": _vm.suggestion.type_id,
        "data-package": _vm.suggestion.package_id,
      },
      on: { click: _vm.handleClick },
    },
    [
      _vm.suggestion.imagesrc
        ? _c("span", { staticClass: "search-result-image" }, [
            _c("span", { staticClass: "item__image-wrapper" }, [
              _c("img", {
                attrs: {
                  src: _vm.suggestion.imagesrc,
                  width: "102",
                  height: "102",
                  alt: "",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "search-result-description" }, [
        _c("span", { staticClass: "search-result-name delta" }, [
          _vm._v(_vm._s(_vm.suggestion.name)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "search-result-headline text" }, [
          _vm._v(_vm._s(_vm.suggestion.headline)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }