"use strict";

import MicroModal from 'micromodal';

const defaults = {
    selectors: {
        modal: '.js-emergency-modal',
    },
    classes: {
        initClass: 'modal-init'
    },
    autoplaySpeed: 5000,
};


export default function initEmergencyModal() {
    let emergencyModal = document.querySelector(defaults.selectors.modal);

    // console.log(emergencyModal);

    if (!emergencyModal) return false;

    MicroModal.init();
    MicroModal.show(emergencyModal.id);

}
