import Flickity from 'flickity-imagesloaded';
import { swiffyslider} from "swiffy-slider";
// import { swiffysliderextensions} from "../vendor/swiffy-slider-extensions";
import {addClass} from "./helpers";





const defaults = {
    selectors: {
        slideshow: '.js-teaser-slideshow',
        slide: '.js-teaser-slide',
    },
    classes: {
        initClass: 'flky-init'
    },
    autoplaySpeed: 5000,
};

const state = {
    slideshows: []
};


function initialize () {
    window.swiffyslider = swiffyslider;

    // window.swiffyslider.extensions = swiffysliderextensions;
    // if (!document.currentScript.hasAttribute("data-noinit")) {
    //     window.addEventListener("load", () => {
    //         swiffyslider.extensions.init();
    //     });
    // }
    window.swiffyslider.init();
//     let slideshows = document.querySelectorAll(defaults.selectors.slideshow);
//
//     if (!slideshows) {
//         return false;
//     }
//
//     if (slideshows) {
//         Array.from(slideshows).forEach(slideshow => {
//
//             console.log(slideshow);
//
//         let autoplay = slideshow.dataset['autoplay'] ? defaults.autoplaySpeed : false;
//
//         let singleSlideshow = new Flickity( slideshow, {
//             // options
// //    cellAlign: 'left',
// //    contain: true,
//             arrowShape: 'M77.4039775,90.8332078 C79.4639281,92.8616076 79.5398336,96.2285954 77.5735171,98.3535857 C75.6072007,100.478576 72.3432663,100.556878 70.2833158,98.5284782 L21,50 L70.2833158,1.47152181 C72.3432663,-0.556877955 75.6072007,-0.478575912 77.5735171,1.64641432 C79.5398336,3.77140455 79.4639281,7.1383924 77.4039775,9.16679217 L35.9356284,50 L77.4039775,90.8332078 Z',
//             cellSelector: defaults.selectors.slide,
//             // pageDots: false,
//             // prevNextButtons: false,
//             wrapAround: true,
//             cellAlign: 'left',
//             autoPlay: autoplay,
//             imagesLoaded: true,
//             // setGallerySize: false
//             // percentPosition: false,
//         });
//
//         addClass(slideshow, defaults.classes.initClass);
//         state.storeslideshows.push(singleSlideshow)
//
//     });
//     }

}


export function initTeaserSlideshows() {

    console.log("slideshows");
    initialize();
}

//export default function sliders() {
//
//  // Slider used on content page
//  var contentSlider = new Swiper('.sdslider-container', {
//    slidesPerView: 1.2,
//    spaceBetween: 0,
//    centeredSlides: true,
//    loop: true,
//    autoplay: {
//      delay: 3000
//    },
//    navigation: {
//      nextEl: '.slider-button-next',
//      prevEl: '.slider-button-prev'
//    },
//    pagination: {
//      el: '.slider-pagination',
//      clickable: true
//    }
//  });
//
//  // Slider used on content page
//  var storeSlider = new Swiper('.store-container', {
//    slidesPerView: 1,
//    spaceBetween: 0,
//    loop: true,
//    autoplay: {
//      delay: 3000
//    },
//    navigation: {
//      nextEl: '.store-button-next',
//      prevEl: '.store-button-prev'
//    },
//    pagination: {
//      el: '.store-pagination',
//      clickable: true
//    }
//
//  });
//
//  // Teaser slider
//  var teaserSlider = new Swiper('.teaserslider-container', {
//    slidesPerView: 3,
//    spaceBetween: 30,
//    loop: true,
//    autoplay: {
//      delay: 3000
//    },
//    navigation: {
//      nextEl: '.teaserslider-button-next',
//      prevEl: '.teaserslider-button-prev'
//    },
//    pagination: {
//      el: '.teaserslider-pagination',
//      clickable: true
//    },
//    breakpoints: {
//      1040: {
//        slidesPerView: 2,
//        spaceBetween: 30,
//      },
//      640: {
//        slidesPerView: 1,
//        spaceBetween: 30,
//      }
//    }
//  });
//}
