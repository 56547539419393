import docReady from 'doc-ready';
import initGlobal from './global.js';
import { lang } from './components/helpers.js'

function initFallbackPage() {

    console.log('Language from fallback: ' + lang);
}

docReady(function () {
    initGlobal();
    initFallbackPage();
});
