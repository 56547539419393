const vbcnOptions = {
    cookie: {
        cookieLifeTime: 12, // * lifeTimeUnit
        lifeTimeUnit: 'month', // month, day, session
    },
    notice: {
        ignoredPages: [
            '/ueber-uns/datenschutz'
        ],
        ignoredHashes: [
            '#hide-my-stuff',
        ],
        backdropColor: '#474747',
        declineOnBackdropClick: false,
        declineMode: 30,
        hasCustomDeclineButton: false,
        hasCustomCustomizeButton: false,
        autoEnableMandatory: true,
        preEnableMandatory: true,
        mandatoryCanBeUnchecked: false,
        useConfigurationExplanation: false,
        useConfigurationAdditionalInfo: true,
    },
    texts: {
        "de": {
            initialHeadline: 'Cookies, externe Dienste & Datenschutz',
            customizeHeadline: 'Deine Datenschutz-Einstellungen',
            explanationText: "Wir verwenden auf unserer Website Dienste von Drittanbietern, die uns helfen unser Webangebot zu verbessern, die Anzeige zu optimieren und nutzerfreundlich zu gestalten. " +
                "Hierzu binden wir Skripte von YouTube und Mapbox ein. Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. " +
                "Um diese Dienste verwenden zu dürfen, benötigen wir vorab eine Einwilligung. Diese Einwilligung kann jederzeit widerrufen werden. " +
                "Über die Schaltfläche „Einstellungen“ sind Informationen zu den eingesetzten Tools erhältlich und hier kann Einwilligung gezielt für einzelne Tools erteilt werden. " +
                "Bitte hier klicken, um weitere Informationen zum <a href='https://www.augsburg-marketing.de/datenschutz'>Datenschutz</a> zu erhalten.\n" +
                "<br/><br/>" +
                "<a href='https://www.augsburg-marketing.de/impressum'>Impressum</a>",
            configuration_explanation_headline: "Was ist ein Cookie?",
            configuration_explanation_text: "Unter <strong>Cookie</strong> versteht man eine kleine <strong>Datei</strong>, die von einer Webseite beim Besuch von Internetseiten auf Deinem Computer oder Smartphone gespeichert wird. Besuchst Du die Website zu einem späteren Zeitpunkt wieder, wird der gespeicherte Cookie an den Webserver übertragen.",
            configuration_additional_headline: "",
            configuration_additional_info: "Weitere Informationen in unserer <a href='https://www.augsburg-marketing.de/datenschutz'>Datenschutzerklärung</a>",
            accept_preset_ButtonText: 'Alle akzeptieren',
            declineButtonText: 'Ablehnen ',
            accept_all_ButtonText: 'Alle Cookies akzeptieren',
            decline_all_ButtonText: 'Alle ablehnen',
            customizeButtonText: 'Einstellungen',
            cancel_customizeButtonText: 'Zurück',
            saveButtonText: 'Speichern',
            closeButtonText: '×'
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Diese Cookies sind technisch erforderlich, um die Kernfunktionalität der Website sicherzustellen. Dies umfasst die einwandfreie Funktion und das Design unserer Website.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'mapbox_maps',
                name: 'Mapbox',
                description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an den Anbieter Mapbox übertragen werden.'
            }, {
                id: 'youtube_video',
                name: 'YouTube Video',
                description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos abzuspielen. Durch das Aktivieren können personenbezogene Daten an den Anbieter YouTube übertragen werden.'
            }, {
                id: 'mailjet',
                name: 'Mailjet',
                description: 'Ja, zeigt mir die Newsletter-Anmeldung.Ich weiß, dass hierfür Skripte und Cookies nötig sind und durch das Aktivieren  personenbezogene Daten an den Anbieter übertragen werden können.'
            }, {
                id: 'typeform',
                name: 'Typeform',
                description: 'Ja, zeigt mir alle Formulare. Ich weiß, dass hierfür Skripte und Cookies nötig sind und durch das Aktivieren personenbezogene Daten an den Anbieter übertragen werden können. '
            }, {
                id: 'advent_calendar',
                name: 'Gewinnspiel',
                description: 'Ja, zeigt mir den Adventskalender. Ich weiß, dass hierfür Skripte und Cookies nötig sind und durch das Aktivieren personenbezogene Daten an den Anbieter übertragen werden können. '
            }, {
                id: 'parking_status',
                name: 'Parkhaus Status',
                description: 'Ja, zeigt mir den aktuellen Status der Parkhäuser. Ich weiß, dass hierfür Skripte und Cookies nötig sind und durch das Aktivieren personenbezogene Daten an den Anbieter übertragen werden können.'
            }
        ]
    }
}

export default vbcnOptions;
