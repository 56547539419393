"use strict";

import {addClass, removeClass} from "./helpers";

const defaults = {
  selectors: {
      videos: '.js-video-container',
  },
  classes: {
      initClass: 'vid-init',
  },
};

const state = {
  videos: []
};

function initialize () {
  let videos = document.querySelectorAll(defaults.selectors.videos);

  if (!videos) {
    return false;
  }

  if (videos) {
    Array.from(videos).forEach(video => {
      console.log(video);

        const unmuteBtn = video.querySelector('[data-video-unmute]');
        // const unmuteBtnText = video.querySelector('[data-video-unmute] span');

        const pauseBtn = video.querySelector('[data-video-pause]');
        const pauseBtnText = video.querySelector('[data-video-pause] span');

        const videoEl = video.querySelector('[data-video-element]');
        if (unmuteBtn && videoEl) {
            unmuteBtn.addEventListener('click',() => {
                if (videoEl.muted) {
                    videoEl.muted = false;
                    removeClass(unmuteBtn, 'is-muted')
                    // if (unmuteBtnText) {
                    //     unmuteBtnText.innerHTML = unmuteBtn.dataset.soundOff;
                    // }
                } else {
                    videoEl.muted = true;
                    addClass(unmuteBtn, 'is-muted')
                    // unmuteBtnText.innerHTML = unmuteBtn.dataset.soundOn;
                }
            });
        }

        if (pauseBtn && videoEl) {
            pauseBtn.addEventListener('click',() => {
                if (videoEl.paused) {
                    videoEl.play();
                    removeClass(pauseBtn, 'is-paused')
                    // if (pauseBtnText) {
                    //     pauseBtnText.innerHTML = pauseBtn.dataset.pauseOn;
                    // }
                } else {
                    videoEl.pause();
                    addClass(pauseBtn, 'is-paused')
                    // pauseBtnText.innerHTML = pauseBtn.dataset.pauseOff;
                }
            });

            videoEl.addEventListener("ended", (event) => {
                videoEl.pause();
                addClass(pauseBtn, 'is-paused')
                // pauseBtnText.innerHTML = pauseBtn.dataset.pauseOff;
            });
        }

      addClass(video, defaults.classes.initClass);
      state.videos.push(video)

    });
  }

  console.log(state);


}



export function initVideos() {

  console.log("slideshows");
  initialize();
}
