"use strict";

import Flickity from 'flickity-imagesloaded';
import {addClass} from "./helpers";


const defaults = {
    selectors: {
        slideshow: '.js-image-slideshow',
        slide: '.js-image-slide',
    },
    classes: {
        initClass: 'flky-init',
        counterContainer: 'slideshow-counter',
        counterCurrent: 'current',
        counterDivider: 'divider',
        counterTotal: 'total',
    },
    autoplaySpeed: 5000,
};

const state = {
    slideshows: []
};

let panelWrapper, flkty, nextButton, prevButton, menuButton, menu;


function initialize () {
    let slideshows = document.querySelectorAll(defaults.selectors.slideshow);

    if (!slideshows) {
        return false;
    }

    if (slideshows) {
        Array.from(slideshows).forEach(slideshow => {

            console.log(slideshow);

            const autoplay = slideshow.dataset['autoplay'] ? defaults.autoplaySpeed : false;
            const itemCount = slideshow.dataset['itemCount'] ? slideshow.dataset['itemCount'] : false;
            const slides = slideshow.querySelectorAll(defaults.selectors.slide);

            let slideShowObj = {
                slideshow,
                slides,
                itemCount,
                autoplay
            };

            slideShowObj.singleSlideshow = new Flickity( slideshow, {
                // options
//    cellAlign: 'left',
//    contain: true,
                arrowShape: 'M77.4039775,90.8332078 C79.4639281,92.8616076 79.5398336,96.2285954 77.5735171,98.3535857 C75.6072007,100.478576 72.3432663,100.556878 70.2833158,98.5284782 L21,50 L70.2833158,1.47152181 C72.3432663,-0.556877955 75.6072007,-0.478575912 77.5735171,1.64641432 C79.5398336,3.77140455 79.4639281,7.1383924 77.4039775,9.16679217 L35.9356284,50 L77.4039775,90.8332078 Z',
                cellSelector: defaults.selectors.slide,
                // pageDots: false,
                // prevNextButtons: false,
                wrapAround: true,
                autoPlay: autoplay,
                imagesLoaded: true,
                // setGallerySize: false
                // percentPosition: false,
                adaptiveHeight: true,
                on: {
                    ready: function() {
                        slideShowObj.pager =  slideShowObj.slideshow.querySelector('.flickity-page-dots');

                        if (itemCount > 20) {
                            buildPagerCounter(slideShowObj);
                        }
                    },
                    change: function( index ) {
                        updatePagerCounter(slideShowObj, index);
                    }
                }
            });

            addClass(slideshow, defaults.classes.initClass);
            state.slideshows.push(slideShowObj)

        });
    }

    console.log(state);


}

function buildPagerCounter(slideShow, currentIdx) {

    let counterContainer = document.createElement('div');
    addClass(counterContainer, defaults.classes.counterContainer);

    let counterCurrent = document.createElement('span');
    addClass(counterCurrent, defaults.classes.counterCurrent);

    let counterDivider = document.createElement('span');
    addClass(counterDivider, defaults.classes.counterDivider);
    // counterDivider.innerText = lang === 'de' ? ' von ' : ' of ';
    counterDivider.innerText = ' / ';

    let counterTotal = document.createElement('span');
    addClass(counterTotal, defaults.classes.counterTotal);

    counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
    counterTotal.innerText = slideShow.itemCount;

    counterContainer.append(counterCurrent, counterDivider, counterTotal );

    addClass(slideShow.pager, 'counter');

    slideShow.pager.append(counterContainer);

    slideShow.counterContainer = counterContainer;
    slideShow.counterCurrent = counterCurrent;
    slideShow.counterDivider = counterDivider;
    slideShow.counterTotal = counterTotal;
    slideShow.hasCounter = true;
}

function updatePagerCounter(slideShow, currentIdx) {
    if (!slideShow.hasCounter) {
        return;
    }

    slideShow.counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
}


export function initGallerySlideshows() {

    console.log("slideshows");
    initialize();
}
