"use strict";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        arrowToTop: '.js-scroll-top',
        // productFilterBlockHeader: '.product-listing-filterblock__title',
        // productNavTriggerElems: '.js-toggle-product-nav',
        // productNavElem: '.js-product-nav',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindScrollTop() {
    let linkToTop = document.querySelector(defaults.selectors.arrowToTop);

    // console.log(linkToTop);

    if (!linkToTop) return false;

    // console.log(linkToTop);

    linkToTop.addEventListener('click', function (event) {
        event.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        // scrollTo(document.documentElement, 0, defaults.settings.scrollDuration);
        // console.log("bound");
    })
}
