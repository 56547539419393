"use strict";

import {nodeListToArray, addClass, removeClass, isMobile, hasClass} from "./helpers";
import _merge from 'lodash/merge';
import dynamics from 'dynamics.js';

// import forEach from 'lodash/forEach';

const defaults = {
    selectors: {
        accordionContainer: '.js-accordion',

        accordionElement: '.js-accordion-element',
        accordionElementHead: '.js-accordion-element-head',
        accordionElementContent: '.js-accordion-element-content',
        accordionElementToggle: '.js-accordion-element-toggle',

    },
    classes: {
        activeClass: 'is-active',
        initClass: 'is-accordion-initialized'
    },
    setFirstActive: false,
    closeOthers: true,
};

const state = {
    accordionContainers: null,
    accordions: [],

    accordionNav: null,
    accordionContentsHolder: null,
};

class Accordion {
    constructor(accordionContainer, options) {
        this.accordionContainer = accordionContainer;
        this.options = _merge(defaults, options);

        this.options.setFirstActive = this.accordionContainer.dataset['accordionSetFirstActive'] === 'true';

        this.state = this.setInitialState();


        this.bindToggles();

        console.log(this);


        addClass(this.accordionContainer, this.options.classes.initClass);

    }

    setInitialState() {
        let accordion = {
            container: this.accordionContainer,
            accordionElements: [],
        };

        let accordionElementNodes = this.accordionContainer.querySelectorAll(this.options.selectors.accordionElement);
        console.log(accordionElementNodes);

        Array.from(accordionElementNodes).forEach((accordionElementNode, index) => {

            let accordionElement = {
                element: accordionElementNode,
                head: accordionElementNode.querySelector(this.options.selectors.accordionElementHead),
                content: accordionElementNode.querySelector(this.options.selectors.accordionElementContent),
                toggle: accordionElementNode.querySelector(this.options.selectors.accordionElementToggle),
                isActive: false
            };

            accordion.accordionElements.push(accordionElement);

            if (this.options.setFirstActive && index === 0) {
                this.openAccordionElement(accordionElement);
            } else {
                this.closeAccordionElement(accordionElement);
            }
        });

        return accordion;
    }



    bindToggles() {
        let self = this;
        this.state.accordionElements.forEach(accordionElement => {

            accordionElement.toggle.addEventListener('click', function(e) {
                e.preventDefault();
                self.toggleAccordionElement(accordionElement)
            })
        });

    }

    toggleAccordionElement(accordionElement) {
        console.log(accordionElement);

        if(accordionElement.isActive) {
            this.closeAccordionElement(accordionElement)
        } else {
            if(this.options.closeOthers) {
                let currentlyActive = this.getOpenAccordionElement();
                console.log(currentlyActive);

                currentlyActive.forEach(accordionElement => {
                    this.closeAccordionElement(accordionElement);
                });
                // this.closeAccordionElement();
            }
            this.openAccordionElement(accordionElement)
        }
    }

    openAccordionElement(accordionElement) {
        addClass(accordionElement.element, this.options.classes.activeClass);
        accordionElement.isActive = true;

        let el = accordionElement.element.querySelector('.js-accordion-element-content');
        let height = el.scrollHeight + 'px';

        dynamics.animate(el, {
            height: height,
        }, {
            type: dynamics.easeInOut,
            duration: 100,
        })
    }

    closeAccordionElement(accordionElement) {
        removeClass(accordionElement.element, this.options.classes.activeClass);
        accordionElement.isActive = false;

        let el = accordionElement.element.querySelector('.js-accordion-element-content');

        dynamics.animate(el, {
            height: 0,
        }, {
            type: dynamics.easeInOut,
            duration: 100,
        })
    }

    getOpenAccordionElement() {

        return this.state.accordionElements.filter(accordionElement => accordionElement.isActive);
    }


}


export function initAccordions () {

    let accordionContainers = document.querySelectorAll(defaults.selectors.accordionContainer);

    console.log(accordionContainers);

    if (accordionContainers) {
        console.log('hello');
        console.log(Array.from(accordionContainers));
        Array.from(accordionContainers).forEach(accordionContainer => {
            console.log('horst');
            console.log(accordionContainer);
            new Accordion(accordionContainer);
        });
    }

}
