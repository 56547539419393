"use strict";
import {hasClass} from "../helpers";

const defaults = {
    selectors: {
        analyticsLink: '.js-analytics-track',
    },
    settings: {
    }
};

function bindRegularAnalyticsLinks() {
    let allAnalyticsLinks = document.querySelectorAll(defaults.selectors.analyticsLink);

    if (!allAnalyticsLinks) return false;

    Array.from(allAnalyticsLinks).forEach(analyticsLink => {

        analyticsLink.addEventListener('click', function (event) {
            let el = this;

            sendEventFromEl(el)
        })
    });
}

// @TODO: this does not work with dynamically added elements
// export function bindAnalyticsLinksInContainer(containerSelector) {
//
//     debugger;
//
//     document.querySelector('.js-search-container').addEventListener('click', (event) => {
//         console.log(event.target);
//         let target = event.target;
//
//         event.preventDefault();
//         console.log(target)
//
//
//     }, false);
//
//     document.querySelector(containerSelector).addEventListener('click', (event) => {
//         console.log(event.target);
//         let target = event.target;
//
//         event.preventDefault();
//         console.log(target)
//
//
//     }, false);
//
//
//
//     document.querySelector(containerSelector).addEventListener('click', (event) => {
//         console.log(event.target);
//         let target = event.target;
//
//         if (!hasClass(target, defaults.selectors.analyticsLink)) {
//             return false;
//         }
//
//         event.preventDefault();
//         sendEventFromEl(target)
//
//
//     });
// }

export function sendEventFromEl(el) {

    let category = el.dataset['cat'];
    let action = el.dataset['action'];
    let label = el.dataset['label'];

    if(category && action) {
        sendEvent(category, action, label);
    }
}


// eventCategory	text	yes	Typically the object that was interacted with (e.g. 'Video')
// eventAction	text	yes	The type of interaction (e.g. 'play')
// eventLabel	text	no	Useful for categorizing events (e.g. 'Fall Campaign')
// eventValue	integer	no	A numeric value associated with the event (e.g. 42)

export function sendEvent(category, action, label, type = "event") {

    // console.table([type, category, action, label]);

    // return false;
    if(typeof ga !== 'undefined') {
        ga('send', type, category, action, label);
    } else {
        console.warn("to track you need analytics");
    }

}

export function initAnalyticsTracking() {
    bindRegularAnalyticsLinks();
}
